type Settings = {
  target: string;
  equipmentWeight: string;
  sessionFluid: string;
};

export const readSettings = () => {
  const settings = localStorage.getItem('settings');
  if (settings) {
    return JSON.parse(settings);
  }
  return {
    target: '0.0',
    equipmentWeight: '0.0',
    sessionFluid: '0',
  };
}

export const writeSettings = (settings: Settings) => localStorage.setItem('settings', JSON.stringify(settings));
