import React from "react";
import "./Help.css";

function Help() {
  return (
    <>
      <p className="version">v23.6.2401</p>
      <h2>About this tool</h2>
      <p>
        Dialyse.me is a web app designed to help you remember your vitals before and after a session.
        It is based on the vitals taken at the Royal Berkshire Hospital.
        If you require different data please <a href="mailto:stuart@stut.net?subject=Dialyse.me">let me know</a>.
      </p>
      <p>
        It does not store historic data and there are currently no plans to add that.
        It remembers all current values by storing them in the browser on your device.
        No data is ever sent to external servers.
      </p>
      <p>
        <img className="icon" src="/apple-touch-icon.png" alt="Dialyse.me icon" width="64" height="64" />
        Best experienced as a standalone app from your homescreen.&nbsp;
        <a href="https://www.howtogeek.com/196087/how-to-add-websites-to-the-home-screen-on-any-smartphone-or-tablet/" target="_blank" rel="noreferrer">How do I do this?</a>
      </p>
      <p>
        Built by <a href="https://stut.me/" target="_blank" rel="noreferrer">Stuart Dallas</a>, a dialysis patient at Royal Berkshire Hospital.
        Please email <a href="mailto:stuart@stut.net?subject=Dialyse.me">stuart@stut.net</a> with any problems or other enquiries.
      </p>
      <div className={"terms-conditions"}>
        <h4>Terms &amp; conditions</h4>
        <p>
          THIS SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTY OF ANY KIND, WHETHER WRITTEN OR ORAL, EXPRESS OR IMPLIED.
          STUART DALLAS DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING BUT NOT LIMITED TO ALL WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
          STUART DALLAS DOES NOT REPRESENT OR WARRANT THAT THE SERVICE WILL MEET USER'S NEEDS OR REQUIREMENTS, THAT ANY INFORMATION OBTAINED THROUGH USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, THAT USE OF THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, OR THAT ALL DEFECTS IN THE SERVICE WILL BE CORRECTED.
        </p>
      </div>
      <div className={"privacy-policy"}>
        <h4>Privacy policy</h4>
        <p>
          Your data is never stored, logged, or otherwise communicated to any other system or organisation.
          The server cannot access any of your data at any time.
          All data that's remembered is stored in your browser using <a href="https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage" target="_blank" rel="noreferrer">local storage</a>.
        </p>
      </div>
    </>
  );
}

export default Help;
