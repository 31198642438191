import React from "react";
import "./Field.css";

export default function Field(props: {
  name: string;
  slug: string;
  format: string;
  units: string;
  value: string;
  setter: (v: string) => void;
  tagline?: string;
}) {
  const [value, setValue] = React.useState(props.value);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    props.setter(e.target.value);
  };

  return (
    <>
      <div className="field">
        <label htmlFor={props.slug}>
          {props.name}
          {props.tagline && <span className="tagline">{props.tagline}</span>}
        </label>
        <input
          type="text"
          inputMode="decimal"
          className={value === "" || parseFloat(value) ? "" : "error"}
          id={props.slug}
          name={props.slug}
          value={value === "" ? "" : value?.toString()}
          onFocus={(e) => e.target.select()}
          onChange={handleOnChange}
        />
        <span className="units">{props.units}</span>
      </div>
    </>
  );
}
