import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import Session from './Session';
import Config from './Config';
import Help from './Help';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "session", element: <Session />, },
      { path: "config",  element: <Config />, },
      { path: "help",    element: <Help />, },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
