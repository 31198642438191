import React, { useEffect } from 'react';
import logo from './kidneys.svg';
import './App.css';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from './NavBar';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/session");
    }
  }, [location]);

  return (
    <>
      <header>
        <img src={logo} className="logo" alt="logo" />
        <h1>Dialyse.me</h1>
      </header>
      <NavBar />
      <main className={location.pathname.substring(1)}>
        <Outlet />
      </main>
    </>
  );
}

export default App;
