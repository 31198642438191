import React from "react";
import "./NavBar.css";
import SessionIcon from "./session-icon.svg";
import ConfigIcon from "./config-icon.svg";
import HelpIcon from "./help-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";

const NavBarButton = (props: {title: string, icon: string, isActive: boolean, onClick: () => void}) => {
  const { title, icon, isActive, onClick } = props;

  return (
      <div className={"btn" + (isActive ? " active" : "")} onClick={onClick}>
        <img src={icon} className="logo" alt="logo" />
        <span>{title}</span>
      </div>
  );
};

declare global {
  // This is a horrible hack but it seems to work.
  interface Navigator { standalone: boolean; }
}

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isFullScreen = window.navigator.userAgent.indexOf('iPhone') !== -1 && window.navigator.standalone;

  return (
    <nav className={isFullScreen ? "fullscreen" : ""}>
      <NavBarButton title="Session" icon={SessionIcon} isActive={location.pathname === "/session"} onClick={() => navigate("/session")} />
      <NavBarButton title="Config" icon={ConfigIcon} isActive={location.pathname === "/config"} onClick={() => navigate("/config")} />
      <NavBarButton title="Help" icon={HelpIcon} isActive={location.pathname === "/help"} onClick={() => navigate("/help")} /> 
    </nav>
  );
};

export default NavBar;
