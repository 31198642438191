import React, { useState } from "react";
import { readValue } from "./utils";
import "./Session.css";
import { useNavigate } from "react-router-dom";
import Field from "./Field";
import { readSettings } from "./settings";
import {
  keyEditingPre,
  keyPreTemp,
  keyPreSaturation,
  keyPreWeight,
  keyEditingPost,
  keyPostWeight,
} from "./constants";

function Session() {
  const navigate = useNavigate();

  const { target, equipmentWeight, sessionFluid } = readSettings();

  const [editingPre, setEditingPre] = useState(readValue(keyEditingPre, "1"));
  const [preTemp, setPreTemp] = useState(readValue(keyPreTemp, ""));
  const [preSaturation, setPreSaturation] = useState(readValue(keyPreSaturation, ""));
  const [preWeight, setPreWeight] = useState(readValue(keyPreWeight, ""));

  const [editingPost, setEditingPost] = useState(readValue(keyEditingPost, "1"));
  const [postWeight, setPostWeight] = useState(readValue(keyPostWeight, ""));

  const [confirmingReset, setConfirmingReset] = useState(false);

  const setValue = (key: string, value: string, setter: (v: string) => void) => {
    setter(value);
    localStorage.setItem(key, value);
  };

  const resetSessionData = () => {
    setPreTemp("");
    setValue(keyEditingPre, "1", setEditingPre);
    setValue(keyPreTemp, "", setPreTemp);
    setValue(keyPreSaturation, "", setPreSaturation);
    setValue(keyPreWeight, "", setPreWeight);
    setValue(keyEditingPost, "1", setEditingPost);
    setValue(keyPostWeight, "", setPostWeight);
    setConfirmingReset(false);
    navigate("/");
  };

  let calculatedPostWeight = 0;
  if (postWeight && equipmentWeight)
    calculatedPostWeight =
      Math.round((parseFloat(postWeight) - parseFloat(equipmentWeight)) * 10) /
      10;

  let preContent = <></>;
  
  if (editingPre === "1") {
    preContent = <div className="pre-session">
      <h2>Pre-session</h2>
      <Field
        name="Temperature"
        slug="pre-temp"
        format="number"
        units="&deg;C"
        value={preTemp}
        setter={(v) => setValue(keyPreTemp, v, setPreTemp)}
      />
      <Field
        name="Saturation"
        slug="pre-saturation"
        format="number"
        units="%"
        value={preSaturation}
        setter={(v) => setValue(keyPreSaturation, v, setPreSaturation)}
      />
      <Field
        name="Weigh-in"
        slug="pre-weight"
        format="number"
        units="kg"
        value={preWeight}
        setter={(v) => setValue(keyPreWeight, v, setPreWeight)}
      />
      <button className={parseInt(preWeight) ? "enabled" : "disabled"} onClick={() => setValue(keyEditingPre, "0", setEditingPre)}>Show calculation</button>
    </div>;
  } else {
    let calculatedPreWeight = null;
    if (preWeight && equipmentWeight)
      calculatedPreWeight =
        Math.round((parseFloat(preWeight) - parseFloat(equipmentWeight)) * 10) /
        10;

    let takeOffFluid = 0;
    if (sessionFluid && preWeight && equipmentWeight && target)
      takeOffFluid =
        parseFloat(sessionFluid) +
        Math.round(
          (parseFloat(preWeight) -
            parseFloat(equipmentWeight) -
            parseFloat(target)) *
            1000 *
            10
        ) /
          10;

    preContent = <>
      <summary className="pre">
        <p>Pre-session weight is</p>
        <h2>{calculatedPreWeight} kg</h2>
        <h4>
          {takeOffFluid > 0 && "Take off " + takeOffFluid + "ml"}
          {takeOffFluid === 0 && "Nothing to take off"}
          {takeOffFluid < 0 && "Add " + Math.abs(takeOffFluid) + " ml?"}
        </h4>
        <p className="temperature">Temperature: <span className="data">{preTemp}&deg;C</span></p>
        <p className="saturation">Saturation: <span className="data">{preSaturation}%</span></p>
      </summary>
      <button onClick={() => setValue(keyEditingPre, "1", setEditingPre)}>Edit pre-session vitals</button>
    </>;
  }

  let postContent = <></>;
  if (editingPost === "1") {
    postContent = <div className="post-session">
      <h2>Post-session</h2>
      <Field
        name="Weigh-out"
        slug="post-weight"
        format="number"
        units="kg"
        value={postWeight}
        setter={(v) => setValue(keyPostWeight, v, setPostWeight)}
      />
      <button className={parseInt(postWeight) ? "enabled" : "disabled"} onClick={() => setValue(keyEditingPost, "0", setEditingPost)}>Show calculation</button>
    </div>;
  } else {
    let postDifference =
        Math.round((calculatedPostWeight - parseFloat(target)) * 10) / 10;
    postContent = <>
      <summary className="post">
        <p>Post-session weight is</p>
        <h2>{calculatedPostWeight} kg</h2>
        <h4>
          {postDifference > 0 && Math.abs(postDifference) + " kg over target"}
          {postDifference === 0 && "Target weight achieved!"}
          {postDifference < 0 && Math.abs(postDifference) + " kg under target"}
        </h4>
      </summary>
      <button onClick={() => setValue(keyEditingPost, "1", setEditingPost)}>Edit post-session vitals</button>
    </>;
  }

  const showReset = preTemp || preSaturation || preWeight || postWeight;
  const showConfirmReset = showReset && confirmingReset;

  return (
    <>
      {!parseInt(target) && <button className="noTarget" onClick={() => navigate("/config")}>Tap here to set your target weight<br />before starting a session</button>}
      {preContent}
      {postContent}
      {showReset && !confirmingReset && <button className="resetBtn" onClick={() => setConfirmingReset(true)}>Reset session data</button>}
      {showConfirmReset && <div className="confirmReset">
        <button className="resetBtn" onClick={() => resetSessionData()}>Reset</button>
        <button onClick={() => setConfirmingReset(false)}>Cancel</button>
      </div>}
    </>
  );
}

export default Session;
