import React from "react";
import "./Config.css";
import { useNavigate } from "react-router-dom";
import Field from "./Field";
import { readSettings, writeSettings } from "./settings";

function Config() {
  const navigate = useNavigate();
  
  let loadedSettings = readSettings();
  const [target, setTarget] = React.useState(loadedSettings["target"] || "");

  const saveSetting = (key: string, value: string) => {
    loadedSettings[key] = value;
    writeSettings(loadedSettings);
    if (key === "target") {
      setTarget(value);
    }
  };

  return (
    <>
      <h2>Configuration</h2>
      <Field
        name="Target weight"
        slug="target"
        format="number"
        units="kg"
        value={loadedSettings["target"]}
        setter={(value) => saveSetting("target", value)}
        tagline="What is your dry weight target?"
      />
      <Field
        name="Equipment weight"
        slug="equip-weight"
        format="number"
        units="kg"
        value={loadedSettings["equipmentWeight"]}
        setter={(value) => saveSetting("equipmentWeight", value)}
        tagline="What's the weight of any equipment you weigh in and out with (wheelchair, orthopaedic boot, etc)."
      />
      <Field
        name="Session fluid"
        slug="session-fluid"
        format="number"
        units="ml"
        value={loadedSettings["sessionFluid"]}
        setter={(value) => saveSetting("sessionFluid", value)}
        tagline="How much fluid do you consume during the session?"
      />
      {target !== "" && <button className="noTarget" onClick={() => navigate("/session")}>Start your session...</button>}
    </>
  );
}

export default Config;
